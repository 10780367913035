<template>
  <el-menu-item :index="itemOrMenu.path" @click="handleLink">
    <vab-icon
      v-if="itemOrMenu.meta && itemOrMenu.meta.icon"
      :icon="itemOrMenu.meta.icon"
      :is-custom-svg="itemOrMenu.meta.isCustomSvg"
      :title="translateTitle(itemOrMenu.meta.title)"
    />
    <span :title="translateTitle(itemOrMenu.meta.title)">
      {{ translateTitle(itemOrMenu.meta.title) }}
    </span>
    <el-tag
      v-if="itemOrMenu.meta && itemOrMenu.meta.badge"
      effect="dark"
      type="danger"
    >
      {{ itemOrMenu.meta.badge }}
    </el-tag>
    <span
      v-if="itemOrMenu.meta && itemOrMenu.meta.dot"
      class="vab-dot vab-dot-error"
    >
      <span />
    </span>
  </el-menu-item>
</template>

<script>
  import { computed, defineComponent, getCurrentInstance } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { isExternal } from '@/utils/validate'
  import { translateTitle } from '@/utils/i18n'
  import { isHashRouterMode } from '@/config'

  export default defineComponent({
    name: 'VabMenuItem',
    props: {
      itemOrMenu: {
        type: Object,
        default() {
          return null
        },
      },
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const device = computed(() => store.getters['settings/device'])
      const foldSideBar = () => store.dispatch('settings/foldSideBar')

      const { proxy } = getCurrentInstance()

      const handleLink = () => {
        const routePath = props.itemOrMenu.path
        console.log(routePath, '======>>>>')
        const target = props.itemOrMenu.meta.target
        if (target === '_blank') {
          if (isExternal(routePath)) window.open(routePath)
          else if (route.path !== routePath)
            isHashRouterMode
              ? window.open('/#' + routePath)
              : window.open(routePath)
        } else {
          if (isExternal(routePath)) window.location.href = routePath
          else if (route.path !== routePath) {
            if (device.value === 'mobile') proxy.foldSideBar()
            router.push(props.itemOrMenu.path)
          } else proxy.$pub('reload-router-view')
        }
      }

      return {
        foldSideBar,
        handleLink,
        translateTitle,
      }
    },
  })
</script>

<style lang="scss" scoped>
  :deep() {
    .el-tag {
      position: absolute;
      right: 20px;
      height: 16px;
      padding-right: 4px;
      padding-left: 4px;
      // margin-top: #{math.div($base-menu-item-height - 16, 2)};
      line-height: 16px;
      border: 0;
    }
  }

  .vab-dot {
    position: absolute !important;
    right: 20px;
    // margin-top: #{math.div($base-menu-item-height - 6, 2)};
  }
</style>
