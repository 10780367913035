<template>
  <div
    class="logo-container"
    :class="{
      ['logo-container-' + theme.layout]: true,
    }"
  >
    <router-link to="/">
      <!-- <span class="logo"> -->
      <!-- 使用自定义svg示例 -->
      <!-- <vab-icon v-if="logo" :icon="logo" is-custom-svg /> -->
      <!-- <img src="@/assets/logo.png" /> -->
      <!-- </span> -->
      <img
        v-if="title.customLogo"
        :src="title.customLogo"
        alt=""
        style="width: 32px; height: 24px; vertical-align: middle"
      />
      <img
        v-else
        src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/wemeet/20230804/faa332b3fe994d6f837dadbc53641302.png"
        alt=""
        style="width: 32px; height: 24px; vertical-align: middle"
      />
      <span
        class="title"
        :class="{ 'hidden-xs-only': theme.layout === 'horizontal' }"
      >
        {{ title.customTitle }}
      </span>
    </router-link>
  </div>
</template>

<script>
  import { computed, defineComponent, reactive, toRefs, onMounted } from 'vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'VabLogo',
    setup() {
      const store = useStore()
      const state = reactive({
        title: {
          tenantName: '',
        },
      })
      onMounted(() => {
        state.title = JSON.parse(localStorage.getItem('customInfo'))
        console.log(state.title)
      })
      return {
        ...toRefs(state),
        logo: computed(() => store.getters['settings/logo']),
        theme: computed(() => store.getters['settings/theme']),
      }
    },
  })
</script>

<style lang="scss" scoped>
  @mixin container {
    position: relative;
    height: $base-header-height;
    overflow: hidden;
    line-height: $base-header-height;
    background: transparent;
  }

  @mixin logo {
    display: inline-block;
    width: 32px;
    height: 32px;
    color: $base-title-color;
    vertical-align: middle;
  }

  @mixin title {
    display: inline-block;
    margin-left: 5px;
    overflow: hidden;
    font-size: 20px;
    line-height: 55px;
    color: $base-title-color;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }

  .logo-container {
    &-horizontal,
    &-common {
      @include container;

      .logo {
        svg,
        img {
          @include logo;
        }
      }

      .title {
        @include title;
      }
    }

    &-vertical,
    &-column,
    &-comprehensive,
    &-float {
      @include container;

      height: $base-logo-height;
      line-height: $base-logo-height;
      text-align: center;

      .logo {
        svg,
        img {
          @include logo;
        }
      }

      .title {
        @include title;
        max-width: $base-left-menu-width - 60;
      }
    }

    &-column {
      background: var(--vab-column-second-menu-background) !important;

      .logo {
        position: fixed;
        top: 0;
        display: block;
        width: $base-left-menu-width-min;
        height: $base-logo-height;
        margin: 0;
        background: var(--vab-column-first-menu-background);
      }

      .title {
        padding-right: 15px;
        padding-left: 15px;
        margin-left: $base-left-menu-width-min !important;
        color: var(--el-color-black) !important;
        background: var(--vab-column-second-menu-background) !important;
        @include title;
      }
    }
  }
</style>
