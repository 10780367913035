/**
 * 投放id
 */
const state = () => ({
  batchNo: 0,
})
const getters = {}
const mutations = {
  setBatchNo(state, batchNo) {
    state.batchNo = batchNo
  },
}
const actions = {
  commitBatchNo({ commit }, batchNo) {
    console.log('store---batchNo', batchNo)
    commit('setBatchNo', batchNo)
  },
}
export default { state, getters, mutations, actions }
