/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import {
  getUserInfo,
  login,
  logout,
  socialLogin,
  getCustomInfoApi,
} from '@/api/user'
import {
  getToken,
  removeToken,
  setToken,
  setcInfo,
  getcInfo,
  setUserInfo,
  removeUser,
} from '@/utils/token'
import { resetRouter } from '@/router'
import { isArray, isString } from '@/utils/validate'
import { title, tokenName } from '@/config'
import { gp } from '@vab'

import router from '@/router'

const state = () => ({
  token: getToken(),
  username: '游客',
  avatar: 'https://i.gtimg.cn/club/item/face/img/2/16022_100.gif',
  customInfo: getcInfo(),
  user: {},
})
const getters = {
  token: (state) => state.token,
  username: (state) => state.username,
  avatar: (state) => state.avatar,
  customInfo: (state) => state.customInfo,
  user: (state) => state.user,
}
const mutations = {
  /**
   * @description 设置token
   * @param {*} state
   * @param {*} token
   */
  setToken(state, token) {
    state.token = token
    setToken(token)
  },
  /**
   * @description 设置用户名
   * @param {*} state
   * @param {*} username
   */
  setUsername(state, username) {
    state.username = username
  },
  /**
   * @description 设置头像
   * @param {*} state
   * @param {*} avatar
   */
  setAvatar(state, avatar) {
    state.avatar = avatar
  },
  /**
   * @description: 设置当前租户基本信息
   * @param {*} state
   * @param {*} customInfo
   * @return {*}
   */
  setCustomInfo(state, customInfo) {
    state.customInfo = customInfo
    setcInfo(customInfo)
  },
  /**
   * @description: 设置当前用户信息
   * @param {*} state
   * @param {*} user
   * @return {*}
   */
  setUser(state, user) {
    state.user = user
    setUserInfo(user)
  },
}
const actions = {
  /**
   * @description 登录拦截放行时，设置虚拟角色
   * @param {*} { commit, dispatch }
   */
  setVirtualRoles({ commit, dispatch }) {
    dispatch('acl/setFull', true, { root: true })
    commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/16022_100.gif')
    commit('setUsername', 'admin(未开启登录拦截)')
  },
  /**
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async login({ commit, state }, userInfo) {
    const {
      data: { [tokenName]: token },
    } = await login(userInfo)
    if (token) {
      commit('setToken', token)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8
          ? '早上好'
          : hour <= 11
          ? '上午好'
          : hour <= 13
          ? '中午好'
          : hour < 18
          ? '下午好'
          : '晚上好'
      gp.$baseNotify(`欢迎登录${state.customInfo.customTitle}`, `${thisTime}！`)
    } else {
      const err = `登录接口异常，未正确返回${tokenName}...`
      gp.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    }
  },
  /**
   * @description 第三方登录
   * @param {*} {}
   * @param {*} tokenData
   */
  async socialLogin({ commit }, tokenData) {
    const {
      data: { [tokenName]: token },
    } = await socialLogin(tokenData)
    if (token) {
      commit('setToken', token)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8
          ? '早上好'
          : hour <= 11
          ? '上午好'
          : hour <= 13
          ? '中午好'
          : hour < 18
          ? '下午好'
          : '晚上好'
      gp.$baseNotify(`欢迎登录${state.customInfo.customTitle}`, `${thisTime}！`)
    } else {
      const err = `login核心接口异常，请检查返回JSON格式是否正确，是否正确返回${tokenName}...`
      gp.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    }
  },

  /**
   * @description: 微信扫码登录
   * @param {*} commit
   * @param {*} data
   * @return {*}
   */
  async wxlogin({ commit }, data) {
    const accessToken = data[tokenName]
    if (accessToken) {
      commit('setToken', accessToken)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8
          ? '早上好'
          : hour <= 11
          ? '上午好'
          : hour <= 13
          ? '中午好'
          : hour < 18
          ? '下午好'
          : '晚上好'
      gp.$baseNotify(`欢迎登录${title}`, `${thisTime}！`)
    } else {
      const err = `login核心接口异常，请检查返回JSON格式是否正确，是否正确返回${tokenName}...`
      gp.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    }
  },
  /**
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  async getUserInfo({ commit, dispatch }) {
    const data = await getUserInfo()
    const { user, role, permission } = data.data
    /**
     * 检验返回数据是否正常，无对应参数，将使用默认用户名,头像,Roles和Permissions
     * username {String}
     * avatar {String}
     * roles {List}
     * ability {List}
     */
    if (
      (user.username && !isString(user.username)) ||
      (user.avatar && !isString(user.avatar)) ||
      (role && !isArray(role)) ||
      (permission && !isArray(permission))
    ) {
      const err = 'getUserInfo核心接口异常，请检查返回JSON格式是否正确'
      gp.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    } else {
      // 如不使用username用户名,可删除以下代码
      if (user.username) commit('setUsername', user.username)
      // 如不使用avatar头像,可删除以下代码
      if (user.avatar) commit('setAvatar', user.avatar)

      if (user) {
        user.role = role
        commit('setUser', user)
      }
      // 如不使用roles权限控制,可删除以下代码
      if (role) dispatch('acl/setRole', role, { root: true })
      // 如不使用permissions权限控制,可删除以下代码
      if (permission) dispatch('acl/setPermission', permission, { root: true })

      // 权限判断，如果是企业账号，走弹窗选择机构
      if (
        (role && role.indexOf('BUSINESS_ENTERPRISE_ACCOUNT') > -1) ||
        role.indexOf('BUSINESS_EXHIBITION_MANAGE') > -1
      ) {
        let isEnterprise = localStorage.getItem('isEnterpriseItem')
        console.log(isEnterprise, '====vvvvv筛选')
        if (!isEnterprise) {
          console.log(router)
          router.replace('/index')
          setTimeout(() => {
            gp.$pub('enterpriseChoose')
          }, 2000)
        }
      }
    }
  },
  /**
   * @description 退出登录
   * @param {*} { dispatch }
   */
  async logout({ dispatch }) {
    await logout()
    await dispatch('resetAll')
  },

  /**
   * @description: 获取租户基本信息
   * @return {*}
   */
  async getCustomInfo({ commit }, tenantKey) {
    console.log('-=====')
    const { data } = await getCustomInfoApi(tenantKey)
    if (!!data) {
      data['tenantKey'] = tenantKey.tenantKey
    }
    // process.env.VUE_APP_TITLE = data.customTitle
    commit('setCustomInfo', data)
  },
  /**
   * @description 重置token、roles、permission、router、tabsBar等
   * @param {*} { commit, dispatch }
   */
  async resetAll({ commit, dispatch }) {
    commit('setUsername', '游客')
    commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/16022_100.gif')
    commit('setUser', {})
    commit('routes/setRoutes', [], { root: true })
    await dispatch('setToken', '')
    await dispatch('acl/setFull', false, { root: true })
    await dispatch('acl/setRole', [], { root: true })
    await dispatch('acl/setPermission', [], { root: true })
    await dispatch('tabs/delAllVisitedRoutes', null, { root: true })
    await resetRouter()
    removeToken()
    removeUser()
  },
  /**
   * @description 设置token
   * @param {*} { commit }
   * @param {*} token
   */
  setToken({ commit }, token) {
    commit('setToken', token)
  },
  /**
   * @description 设置头像
   * @param {*} { commit }
   * @param {*} avatar
   */
  setAvatar({ commit }, avatar) {
    commit('setAvatar', avatar)
  },
}
export default { state, getters, mutations, actions }
