<template>
  <div class="vab-header">
    <div class="vab-main">
      <el-row :gutter="20">
        <el-col :span="6">
          <vab-logo />
        </el-col>
        <el-col :span="18">
          <div class="right-panel">
            <el-menu v-if="'horizontal' === layout" :active-text-color="variables['menu-color-active']" :background-color="variables['menu-background']" :default-active="activeMenu" menu-trigger="hover" mode="horizontal" popper-append-to-body style="width: 100%"
              :text-color="variables['menu-color']">
              <template v-for="(route, index) in handleRoutes" :key="index + route.name">
                <vab-menu v-if="route.meta && !route.meta.hidden" :item="route" :layout="layout" />
              </template>
            </el-menu>
            <!-- <vab-error-log /> -->
            <vab-lock />
            <vab-search />
            <vab-notice />
            <vab-enterprise v-if="isShow" />
            <vab-full-screen />
            <vab-language />
            <!-- <vab-theme /> -->
            <vab-refresh />
            <vab-avatar />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { handleActivePath, handleMatched } from '@/utils/routes'
import variables from '@/vab/styles/variables/variables.scss'
import { use } from 'element-plus/es/locale'

export default defineComponent({
  name: 'VabHeader',
  props: {
    layout: {
      type: String,
      default: 'horizontal',
    },
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const routes = computed(() => store.getters['routes/routes'])
    const activeName = computed(() => store.getters['routes/activeName'])
    const userInfo = computed(() => {
      return store.getters['user/user']
    })

    const isShow = computed(() => {
      // return userInfo
      let userInfo = store.getters['user/user']
      if (userInfo.role) {
        return (
          userInfo.role.indexOf('BUSINESS_ENTERPRISE_ACCOUNT') > -1 ||
          userInfo.role.indexOf('BUSINESS_EXHIBITION_MANAGE') > -1
        )
      }
    })
    const activeMenu = ref()
    const menuTrigger = ref('hover')

    const isEnterPrise = ref()

    const handleRoutes = computed(() =>
      routes.value.flatMap((_route) => {
        return _route.meta &&
          _route.meta.levelHidden === true &&
          _route.children
          ? _route.children
          : _route
      })
    )

    watchEffect(() => (activeMenu.value = handleActivePath(route)))

    watch([() => activeName.value, () => userInfo], ([val, role]) => {
      const matched = handleMatched(routes.value, val)
      activeMenu.value = matched[matched.length - 1].path
    })

    return {
      variables,
      activeMenu,
      menuTrigger,
      handleRoutes,
      isEnterPrise,
      userInfo,
      isShow,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-header {
  display: flex;
  align-items: center;
  justify-items: flex-end;
  height: $base-header-height;
  background: var(--vab-menu-background);

  .vab-main {
    padding: 0 $base-padding 0 $base-padding;

    .right-panel {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: $base-header-height;

      :deep() {
        .el-sub-menu__icon-more {
          margin-top: #{math.div($base-header-height - 20, 2)} !important;
        }

        > .el-menu--horizontal.el-menu {
          > .el-sub-menu > .el-sub-menu__title {
            padding-right: 0;

            > .el-sub-menu__icon-arrow {
              position: relative !important;
              //margin-top: #{math.div($base-header-height - 11, 2)} !important;
              margin-top: -5px !important;
              margin-right: 0;
              margin-left: 30px;
            }
          }

          > .el-menu-item {
            .el-tag {
              position: relative !important;
              margin-top: 0 !important;
              margin-right: -20px;
              margin-left: 25px;
            }

            .vab-dot {
              float: right;
              margin-top: #{math.div($base-header-height - 6, 2)} + 1;
            }

            @media only screen and (max-width: 1199px) {
              .el-tag {
                display: none;
              }
            }
          }
        }

        .el-menu {
          &.el-menu--horizontal {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: $base-header-height;
            border-bottom: 0 solid transparent !important;

            .el-menu-item,
            .el-sub-menu__title {
              height: #{math.div($base-header-height, 1.3)};
              padding: 0 $base-padding;
              line-height: #{math.div($base-header-height, 1.3)};
            }

            > .el-menu-item,
            > .el-sub-menu {
              height: $base-header-height;
              line-height: $base-header-height;

              .el-sub-menu__icon-arrow {
                float: right;
                margin-top: #{math.div($base-menu-item-height - 16, 2)};
              }

              > .el-sub-menu__title {
                display: flex;
                align-items: flex-start;
                height: $base-header-height;
                line-height: $base-header-height;
              }
            }
          }

          [class*='ri-'] {
            margin-left: 0;
            color: var(--el-color-white);
            cursor: pointer;
          }

          .el-sub-menu,
          .el-menu-item {
            i {
              color: inherit;
            }

            &.is-active {
              border-bottom: 0 solid transparent;

              .el-sub-menu__title {
                border-bottom: 0 solid transparent;
              }
            }
          }

          .el-menu-item {
            &.is-active {
              background: var(--el-color-primary) !important;
            }
          }
        }

        .user-name {
          color: var(--el-color-white);
        }

        .user-name + i {
          color: var(--el-color-white);
        }

        [class*='ri-'] {
          margin-left: $base-margin;
          color: var(--el-color-white);
          cursor: pointer;
        }

        button {
          svg {
            margin-right: 0;
            color: var(--el-color-white);
            cursor: pointer;
            fill: var(--el-color-white);
          }
        }
      }
    }
  }
}
</style>

<!--由于element-plus
bug使用popper-append-to-body=false会导致多级路由无法显示，故所有菜单必须生成至body下，样式必须放到body下-->
<style lang="scss">
@mixin menuActiveHover {
  &:hover,
  &.is-active {
    i {
      color: var(--el-color-white) !important;
    }

    color: var(--el-color-white) !important;
    background: var(--el-color-primary) !important;

    .el-sub-menu__title {
      i {
        color: var(--el-color-white) !important;
      }

      color: var(--el-color-white) !important;
      background: var(--el-color-primary) !important;
    }
  }
}

.el-popper {
  .el-menu--horizontal {
    height: #{math.div($base-header-height, 1.3)};
    border-bottom: 0 solid transparent !important;

    @media only screen and (max-width: 1199px) {
      .el-tag {
        display: none;
      }
    }

    .el-tag {
      position: absolute;
      right: 20px;
      margin-top: 0 !important;
    }

    .vab-dot {
      position: absolute;
      right: 20px;
      margin-top: 0 !important;
    }

    .el-menu-item,
    .el-sub-menu {
      height: #{math.div($base-header-height, 1.3)};
      line-height: #{math.div($base-header-height, 1.3)};
      @include menuActiveHover;

      i {
        color: inherit;
      }

      .el-sub-menu__icon-arrow {
        float: right;
      }

      .el-sub-menu__title {
        height: #{math.div($base-header-height, 1.3)};
        line-height: #{math.div($base-header-height, 1.3)};
        @include menuActiveHover;
      }
    }
  }
}
</style>
