/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */

/**
 *  meta 备注
 * * 超级管理员
 * admin 普通管理员
 * shopAdmin 店铺管理员
 * channel 渠道管理员
 * activeAdmin 活动管理员
 * exhibitionAdmin 展会管理员
 */
const admissionData = {
  superAdmin: ['**'],
  admin: ['admin', '**'],
  normalAll: [
    'admin',
    '**',
    'shopAdmin',
    'channel',
    'activeAdmin',
    'exhibitionAdmin',
    'operateAdmin',
    'passAdmin',
  ],
  shopAdmin: ['admin', '**', 'shopAdmin', 'operateAdmin'],
  channel: ['admin', '**', 'channel'],
  activeAdmin: ['admin', '**', 'activeAdmin'],
  exhibitionAdmin: ['admin', '**', 'exhibitionAdmin'],
  operateAdmin: ['admin', '**', 'shopAdmin', 'operateAdmin', 'activeAdmin'],
  passAdmin: ['admin', '**', 'passAdmin'],
}
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'
import Layout from '@/vab/layouts'
import { authentication, isHashRouterMode, publicPath } from '@/config'

export const constantRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => import('@/views/callback'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: {
      hidden: true,
    },
  },
]

export const asyncRoutes = []

const router = createRouter({
  history: isHashRouterMode
    ? createWebHashHistory(publicPath)
    : createWebHistory(publicPath),
  routes: constantRoutes,
})

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

function addRouter(routes) {
  routes.forEach((route) => {
    if (!router.hasRoute(route.name)) router.addRoute(route)
    if (route.children) addRouter(route.children)
  })
}

export function resetRouter(routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.getRoutes().forEach((route) => {
    const routeName = route.name
    router.hasRoute(routeName) && router.removeRoute(routeName)
  })
  addRouter(routes)
}

export function setupRouter(app) {
  if (authentication === 'intelligence') addRouter(asyncRoutes)
  app.use(router)
  return router
}

export default router
