import { storage, tokenTableName } from '@/config'
import cookie from 'js-cookie'

/**
 * @description 获取token
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getToken() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(tokenTableName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(tokenTableName)
    } else if ('cookie' === storage) {
      return cookie.get(tokenTableName)
    } else {
      return localStorage.getItem(tokenTableName)
    }
  } else {
    return localStorage.getItem(tokenTableName)
  }
}

/**
 * @description 存储token
 * @param token
 * @returns {void|*}
 */
export function setToken(token) {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(tokenTableName, token)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(tokenTableName, token)
    } else if ('cookie' === storage) {
      return cookie.set(tokenTableName, token)
    } else {
      return localStorage.setItem(tokenTableName, token)
    }
  } else {
    return localStorage.setItem(tokenTableName, token)
  }
}

/**
 * @description 移除token
 * @returns {void|Promise<void>}
 */
export function removeToken() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.removeItem(tokenTableName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.clear()
    } else if ('cookie' === storage) {
      return cookie.remove(tokenTableName)
    } else {
      return localStorage.removeItem(tokenTableName)
    }
  } else {
    return localStorage.removeItem(tokenTableName)
  }
}

/**
 * @description: 租户自定义信息
 * @return {*}
 */
export function getcInfo() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem('customInfo') !== null &&
        localStorage.getItem('customInfo') !== 'undefined' &&
        localStorage.getItem('customInfo') !== undefined &&
        localStorage.getItem('customInfo') !== ''
        ? JSON.parse(localStorage.getItem('customInfo'))
        : null
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem('customInfo')
        ? JSON.parse(sessionStorage.getItem('customInfo'))
        : null
    } else if ('cookie' === storage) {
      return cookie.get('customInfo')
        ? JSON.parse(cookie.get('customInfo'))
        : null
    } else {
      return localStorage.getItem('customInfo')
        ? JSON.parse(localStorage.getItem('customInfo'))
        : null
    }
  } else {
    return localStorage.getItem('customInfo')
      ? JSON.parse(localStorage.getItem('customInfo'))
      : null
  }
}

/**
 * @description 租户自定义信息
 * @param token
 * @returns {void|*}
 */
export function setcInfo(customInfo) {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem('customInfo', JSON.stringify(customInfo))
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem('customInfo', JSON.stringify(customInfo))
    } else if ('cookie' === storage) {
      return cookie.set('customInfo', JSON.stringify(customInfo))
    } else {
      return localStorage.setItem('customInfo', JSON.stringify(customInfo))
    }
  } else {
    return localStorage.setItem('customInfo', JSON.stringify(customInfo))
  }
}

/**
 * @author
 * @description userInfo 用户信息
 * @param userInfo
 * @returns {void|*}
 */
export function setUserInfo(userInfo) {
  userInfo = JSON.stringify(userInfo)
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem('userInfo', userInfo)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem('userInfo', userInfo)
    } else {
      return localStorage.setItem('userInfo', userInfo)
    }
  } else {
    return localStorage.setItem('userInfo', userInfo)
  }
}

/**
 * @author
 * @description 移除accessToken
 * @returns {void|Promise<void>}
 */
export function removeUser() {
  localStorage.removeItem('isEnterpriseItem')
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.removeItem('userInfo')
    } else if ('sessionStorage' === storage) {
      return sessionStorage.clear()
    } else {
      return localStorage.removeItem('userInfo')
    }
  } else {
    return localStorage.removeItem('userInfo')
  }
}
